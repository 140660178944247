<script lang="ts" setup>
import { computed, watch, getCurrentInstance } from "vue";
import { getPartyReports } from "@/services/api/party.api";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import useGrid27 from "@/composables/grid27.js";
import GridPagination from "@/components/general/GridPagination.vue";

const { formatDateUtc, formatDateUtcDT } = useDateTimeFormatter();

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

const props = defineProps({
  partyReference: {
    type: Number,
    default: null,
  },
  filterData: {
    type: Array,
    default: function () {
      return [];
    },
  },
});

const _headers = [
  { text: "", value: "doView", sortable: false, width: 20 },
  { text: "", value: "doEdit", sortable: false, width: 20 },
  { text: "Volgorde", value: "order" },
  { text: "Naam", value: "name" },
  { text: "Rapport datum", value: "reportDate" },
  { text: "Workspace Id", value: "workspaceId" },
  { text: "Rapport Id", value: "reportId" },
  { text: "Aangemaakt op", value: "createdDate" },
  { text: "Aangemaakt door", value: "createdBy" },
  { text: "Gewijzigd op", value: "modifiedDate" },
  { text: "Gewijzigd door", value: "modifiedBy" },
];
const headers = computed(() => _headers);

watch(
  () => props.filterData,
  () => {
    fetchFirstPage();
  },
  { immediate: true, deep: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  const pageNumber0 = pageNumber - 1;
  const response = await getPartyReports(props.partyReference, pageNumber0, pageSize, sortBy, sortDesc, props.filterData);
  return response;
}

// async function gotoReportDetails(payload) {
//   router.push({ name: "PowerBIReportDetails", params: { reportId: payload.id, partyReference: props.partyReference } });
// }

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <v-data-table
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      @update:options="changedOptions"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
      class="overview-table"
    >
      <!--@click:row="gotoReportDetails" (commented out from v-data-table, above)-->
      <!--@update:options="changedOptions" (commented out from v-data-table, above)-->
      <template v-slot:[`item.reportDate`]="{ item }">
        {{ formatDateUtc(item.reportDate) }}
      </template>
      <template v-slot:[`item.createdDate`]="{ item }">
        {{ formatDateUtc(item.createdDate) }}
      </template>
      <template v-slot:[`item.modifiedDate`]="{ item }">
        {{ formatDateUtcDT(item.modifiedDate) }}
      </template>
      <template v-slot:[`item.doView`]="{ item }">
        <v-btn :to="{ name: 'Rapportage', params: { partyReference: item.partyReference.toString(), reportId: item.reportId, addModus: false } }" icon small plain>
          <v-icon small> mdi-eye </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.doEdit`]="{ item }">
        <v-btn :to="{ name: 'PowerBIReportDetails', params: { id: item.id, addModus: false } }" icon small plain>
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <grid-pagination :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
