<script lang="ts" setup>
import { ref, getCurrentInstance } from "vue";
import { getPartyReportsFilterOptions } from "@/services/api/party.api";
import { FilterDataName, mapFilterResult, FilterStatus, mapFilterResponseStatus } from "@/stores/filterdata";
import PartyPbiGrid from "@/components/party/PartyPowerBIGrid.vue";

const props = defineProps({
  partyReference: {
    type: Number,
    default: null,
  },
});

const filterData = ref([]);

const filters = ref([
  {
    name: "Naam",
    fieldName: "name",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Rapport datum",
    fieldName: "reportDate",
    type: "range",
    options: [],
  },
  {
    name: "Workspace Id",
    fieldName: "workspaceId",
    type: "list",
    options: [],
  },
  {
    name: "Rapport Id",
    fieldName: "reportId",
    type: "list",
    options: [],
  },
]);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function handleFilterSearch(index, text) {
  //console.log("HandleFilterData handleFilterSearch: ", filterIndex, text);
  let result = [];
  let status = FilterStatus.None;
  switch (filters.value[index].fieldName) {
    case "name":
      if (text && text.length > 0) {
        const response = await getPartyReportsFilterOptions(props.partyReference, filters.value[index].fieldName, text);
        status = mapFilterResponseStatus(response.severity, response.code);
        if (status === FilterStatus.Success) {
          result = response.data.data;
        } else {
          if (status === FilterStatus.Error) {
            proxy.$toaster.error("Ophalen van de data is mislukt! " + response.message);
          }
        }
      }
      break;
    default:
      {
        const response = await getPartyReportsFilterOptions(props.partyReference, filters.value[index].fieldName, "*");
        status = mapFilterResponseStatus(response.severity, response.code);
        if (status === FilterStatus.Success) {
          result = response.data.data;
        } else {
          if (status === FilterStatus.Error) {
            proxy.$toaster.error("Ophalen van de data is mislukt! " + response.message);
          }
        }
      }
      break;
  }
  console.log("HandleFilterData getFilterOptions result: ", result);
  filters.value[index].status = status;
  filters.value[index].options = mapFilterResult(result);
  console.log("HandleFilterData getFilterOptions options: ", filters.value[index].options);
}

async function handleFiltersChanged(newFilterData) {
  console.log("HandleFilterData handleFiltersChanged: ", newFilterData);
  filterData.value = [...newFilterData];
}
</script>

<template>
  <v-container fluid class="inner">
    <v-layout class="overview-filters align-items-start">
      <filter-set :name="FilterDataName.PowerBi" :filters="filters" @filter-search="handleFilterSearch" @filters-changed="handleFiltersChanged"></filter-set>
      <v-spacer />
      <v-btn color="primary" dark :to="{ name: 'PowerBIReportDetails', params: { partyReference: props.partyReference.toString() } }">
        <v-icon dark left>mdi-plus</v-icon>
        Rapport toevoegen
      </v-btn>
    </v-layout>
    <party-pbi-grid :partyReference="partyReference" :filterData="filterData"></party-pbi-grid>
  </v-container>
</template>
